.steps__wrapper {
    width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px auto;
}

.steps__box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin-bottom: 20px;
}

.steps__box__label {
    display: flex;
    align-items: center;
}

.steps__box__label__block {
    color: #fff;
    width: 45px;
    height: 45px;
    background-color: #d3d3d3;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.5s ease, transform 0.5s ease;
}

.steps__box__label__block.active {
    background-color: blue;
    transform: scale(1.0);
}

.steps__box__label__link {
    flex-grow: 1;
    height: 5px;
    transition: background-color 0.5s ease;
    background-color: #ddd;
    width: 90px;
}

.steps__box__label-number {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    margin-left: -18px;
}

.steps__box__label-text {
    text-align: center;
    width: 10%;
    font-size: 12px;
    color: #999;
    margin-top: 70px;
    text-align: center;
    margin-left: -20px;
}