/* General Styles for Navbar */
.navBar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
    padding: 0 2rem;
    z-index: 1000;
}

.navBar.scrolled {
    background-color: #333; /* Change to desired color */
}

/* Wrapper */
.navBarWrapper {
    /* max-width: 1100px; */
    max-width: 1350px;
    margin: 1rem auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Logo */
.navBarLogo img {
    width: 225px;
}

/* Desktop Menu */
.navBarMenu {
    display: flex;
    align-items: center;
}

.navBarMenuList {
    display: flex;
    align-items: center;
    gap: 2rem;
    list-style: none;
}

/* Mobile Menu */
.navBarMobileMenu {
    display: none;
    color: #ffffff;
    cursor: pointer;
}

.navBarMobileMenuToggle {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.navBarMobileMenuItems {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #222222;
    opacity: 0.95;
    padding: 3rem 2rem;
    text-align: center;
    box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
}

.navBarMobileMenuItems.active {
    transform: translateX(0);
}

.navBarMobileMenuList {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    list-style: none;
}

.navBarMobileMenuItem a {
    color: #ffffff;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 1.2rem;
    transition: color 0.3s ease-in-out;
}

.navBarMobileMenuItem a:hover {
    color: var(--primary-color);
}

/* Responsive Design */
@media (max-width: 768px) {
    .navBarLogo img {
        width: 45px;
    }

    .navBarMenu {
        display: none;
    }

    .navBarMobileMenu {
        display: block;
    }

    .navBarLogo img {
        width: 120px;
    }
}