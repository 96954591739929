.table th {
    padding: 0.9rem 0;
    font-size: 1.2rem;
}

.table td {
    padding: 0.9rem 0;
    font-size: 1.2rem;
}

.dollar-sign {
    font-size: 0.9rem;
    font-weight: bold;
    vertical-align: top;
    padding-left: 3px;
}